<template>
  <div>
    로그인 프로세스
  </div>
</template>
<script>
export default {
  created() {

  },
  data () {
    return  {
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/common";
</style>